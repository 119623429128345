<template>
  <div class="m-pg-sub">
    <page-cover code="7" layer="rgba(0,0,0,.3)">
      <h2 class="m-page-title">{{$t('page.reserve.name')}}</h2>
      <el-breadcrumb class="m-breadcrumb m-box-shadow" separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('page.home.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/reserve' }">{{$t('page.reserve.name')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('page.detail.name')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </page-cover>
    <div class="m-pg-sub_main m-w1200">
      <div class="m-art">
        <img src="./images/detail.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
  },

}
</script>

<style lang="less" scoped>
  .m-pg-sub_main {
    margin: 20px auto;
    padding: 30px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
  .m-art {
    &_title {
      margin-bottom: 30px;
      text-align: center;
      font-weight: normal;
    }
    &_content {
      .img {
        margin-bottom: 20px;
        width: 100%;
      }
      p {
        line-height: 26px;
        margin-bottom: 20px;
        text-indent: 2em;
      }
    }
  }
</style>
